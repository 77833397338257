import React from 'react';
import '../App.css';
import './styles/HeroSection.css';

function HeroSection(props) {
  return (
    <div className='hero-container'>
      <video src={props.video} autoPlay loop muted />
		  {/*  <h1>Arthia Projetos</h1>
      <p>Projetos e Controle Qualidade</p>
	<p>Atendemos todo o Brasil</p> */}
      <div className='hero-btns'>
	  {/*
		<Button
          className='btns'
          buttonStyle='btn--outline'
          buttonSize='btn--large'
          link="#contato"
        >
          CONTATE-NOS
        </Button>
        <Button
          className='btns'
          buttonStyle='btn--primary'
          buttonSize='btn--large'
          onClick={console.log('hey')}
        >
           (11) 96334-1874 <i class="fas fa-phone"></i>
        </Button> 
	  */}
      </div>
    </div>
  );
}

export default HeroSection;
