import React, { useState } from 'react';
import './styles/Cards.css';
import ImgModal from "./ImgModal";
import CardItem from './CardItem';

function Cards() {
  const [hidden, setHidden] = useState(true);
  const [source, setSource] = useState("");
  const [text, setText] = useState("");

  const handleClick = (img, text) => {
    setSource(img)
    setText(text)
    setHidden(false)
  }

  return (
    <>
      <ImgModal source={source} text={text} hidden={hidden} onChange={setHidden}></ImgModal>
      <div className='cards' id="servicos">
        <div className='cards__container'>
          <div className="babling-container">
            <h3>Projetos e Controle Qualidade <br />Atendemos todo o Brasil</h3>
          </div>
          <div className='cards__wrapper'>
            <h1>Metrologia</h1>
            <ul className='cards__items'>
              <CardItem
                src='images/metrologia-1.jpg'
                text='Controles e ajustes de dispositivos'
                onClick={handleClick}
              />
            </ul>
            <ul className='cards__items'>
              <CardItem
                src='images/metrologia-2.jpg'
                text='Controle dimensional de produto'
                onClick={handleClick}
              />

              <CardItem
                src='images/metrologia-3.jpg'
                text='Controle GD&T'
                onClick={handleClick}
              />
              <CardItem
                src='images/metrologia-4.jpg'
                text='Relatórios Dimensionais'
                onClick={handleClick}
              />
            </ul>
            <h1 style={{ marginBottom: '4rem', marginTop: "4rem" }}>Projetos</h1>

            <ul className='cards__items'>
              <CardItem
                src='images/projeto-1.jpg'
                text='Projetos de Moldes e Coquilhas'
                onClick={handleClick}
              />

            </ul>
            <ul className='cards__items'>

              <CardItem
                src='images/projeto-3.jpg'
                text='Projetos de Termo-Formados'
                onClick={handleClick}
              />

              <CardItem
                src='images/projeto-2.jpg'
                text='Projetos de Fundição em Aço'
                onClick={handleClick}
              />
            </ul>
            <h1 style={{ marginBottom: '4rem', marginTop: "3em" }}>Digitalização 3D</h1>
            <ul className='cards__items'>
              <CardItem
                src='images/dimensionamento-1.jpg'
                text='Envie uma foto para orçamento, temos o melhor preço do mercado!'
                onClick={handleClick}
              />
            </ul>

            <h1 style={{ marginBottom: '4rem', marginTop: "4rem" }}>Equipamentos</h1>
            <ul className='cards__items'>

              <CardItem
                src='images/equip-1.jpg'
                text='PolyWorks 2020 Metrology'
                onClick={handleClick}
              />

              <CardItem
                src='images/equip-2.jpg'
                text='Siemens NX'
                onClick={handleClick}
              />

              <CardItem
                src='images/equip-3.jpg'
                text='Controle Dimensional - Braço de Medição Mitutoyo'
                onClick={handleClick}
              />

              <CardItem
                src='images/equip-4.jpg'
                text='Controle Dimensional - Tridimensional Mitutoyo CP-JR'
                onClick={handleClick}
              />

              <CardItem
                src='images/equip-5.jpg'
                text='Capacidade de Manipulação - Empilhadeira'
                onClick={handleClick}
              />
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default Cards;
