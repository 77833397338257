import React from 'react';
import Navbar from './components/Navbar';
import './App.css';
import Home from './components/pages/Home';
import Home2 from './components/pages/Home2';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import QuemSomos from './components/pages/QuemSomos';

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/home2' exact component={Home2} />
          <Route path='/quem-somos' component={QuemSomos} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
