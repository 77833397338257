import React, { useState} from 'react';
import { Button } from "./Button";


export default function ContactForm(){

    const [contact, setContact] = useState({
        name: '',
        email: '',
        subject: '',
        message: '',
        replyTo: '@', // this will set replyTo of email to email address entered in the form
        accessKey: 'fc8b9f3b-0fdf-4d04-8f59-4218ae8887a6' // get your access key from https://www.staticforms.xyz
      });
    
      const handleChange = e =>
        setContact({ ...contact, [e.target.name]: e.target.value });
    
      const handleSubmit = async e => {
        e.preventDefault();
        
        console.log(contact);

        setContact({ ...contact, replyTo: `@${contact.email}` });

        try {
          const res = await fetch('https://api.staticforms.xyz/submit', {
            method: 'POST',
            body: JSON.stringify(contact),
            headers: { 'Content-Type': 'application/json' }
          });
    
          const json = await res.json();
    
          if (json.success) {
            alert(`Obrigado por nos contatar ${contact.name}! Em breve retornaremos o email.`);
          } else {
            alert(`Houve um erro ao encaminhar sua mensagem, tente mais tarde, ou contate-nos pelas nossas sociais.`);
          }
        } catch (e) {
          console.log('An error occurred', e);
          alert(`Houve um erro ao encaminhar sua mensagem, tente mais tarde, ou contate-nos pelas nossas sociais.`);
        }
      };
    return(
        <section className='footer-subscription'>
        <p className='footer-subscription-heading'>
          Gostaria de nos mandar uma mensagem? Deixe seu recado aqui!
        </p>
        <p className='footer-subscription-text'>
          Responderemos assim que possível
        </p>
          <form action='https://api.staticforms.xyz/submit' method='post' className='input-areas' onSubmit={handleSubmit}>
            <input className='footer-input' name='name' type='text' placeholder='Seu Nome' onChange={handleChange} />
            <input className='footer-input'name='email' type='email' placeholder='Seu Email' onChange={handleChange} />
            <input className='footer-input'name='subject' type='text' placeholder='Assunto do Seu Email' onChange={handleChange} />
            <textarea className="footer-input" name="message" cols="30" rows="10" placeholder="Sua Mensagem" onChange={handleChange} ></textarea>
            <Button buttonStyle='btn--outline'>Enviar</Button>
          </form>
      </section>

    );
}