import React, { useState } from 'react';

import "./styles/Carousel.css";


export default function Carousel () {
    const [check, setCheck] = useState(1);

    return (
        <section>
            <h1 style={{marginTop: "-10px"}}>Equipamentos e Tecnologia</h1>
            <div className="container">
                <div className="carousel">
                    <input type="radio" name="slides" onChange={() => setCheck(1)} checked={check === 1} id="slide-1" />
                    <input type="radio" name="slides" onChange={() => setCheck(2)} checked={check === 2} id="slide-2" />
                    <input type="radio" name="slides" onChange={() => setCheck(3)} checked={check === 3} id="slide-3" />
                    <input type="radio" name="slides" onChange={() => setCheck(4)} checked={check === 4} id="slide-4" />
                    <input type="radio" name="slides" onChange={() => setCheck(5)} checked={check === 5} id="slide-5" />
                    <input type="radio" name="slides" onChange={() => setCheck(6)} checked={check === 6} id="slide-6" />
                    <ul className="carousel__slides">
                        <li className="carousel__slide">
                            <figure>
                                <div>
                                    <img src="images/carousel-1.png" alt="Carousel-1" />
                                </div>
                                {/* <figcaption>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                    <span className="credit">Photo: Steve Carter</span>
                                </figcaption> */}
                            </figure>
                        </li>
                        <li className="carousel__slide">
                            <figure>
                                <div>
                                    <img src="images/carousel-2.png" alt="Carousel-2" />
                                </div>
                            </figure>
                        </li>
                        <li className="carousel__slide">
                            <figure>
                                <div>
                                <img src="images/carousel-3.png" alt="Carousel-3" />

                                </div>
                            </figure>
                        </li>
                        <li className="carousel__slide">
                            <figure>
                                <div>
                                <img src="images/carousel-4.png" alt="Carousel-4" />

                                </div>
                            </figure>
                        </li>
                        <li className="carousel__slide">
                            <figure>
                                <div>
                                <img src="images/carousel-5.png" alt="Carousel-5" />

                                </div>
                            </figure>
                        </li>
                        <li className="carousel__slide">
                            <figure>
                                <div>
                                <img src="images/carousel-6.png" alt="Carousel-6" />

                                </div>
                            </figure>
                        </li>
                    </ul>
                    <ul className="carousel__thumbnails">
                        <li>
                            <label htmlFor="slide-1"><img src="images/carousel-1.png" width="150" height="150" alt="Thumbnail-1" /></label>
                        </li>
                        <li>
                            <label htmlFor="slide-2"><img src="images/carousel-2.png" width="150" height="150" alt="Thumbnail-2" /></label>
                        </li>
                        <li>
                            <label htmlFor="slide-3"><img src="images/carousel-3.png" width="150" height="150" alt="Thumbnail-3" /></label>
                        </li>
                        <li>
                            <label htmlFor="slide-4"><img src="images/carousel-4.png" width="150" height="150" alt="Thumbnail-4" /></label>
                        </li>
                        <li>
                            <label htmlFor="slide-5"><img src="images/carousel-5.png" width="150" height="150" alt="Thumbnail-5" /></label>
                        </li>
                        <li>
                            <label htmlFor="slide-6"><img src="images/carousel-6.png" width="150" height="150" alt="Thumbnail-6" /></label>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    );
}