import React from 'react';
import '../../App.css';
import Carousel from "../Carousel"
import Footer from "../Footer"

export default function QuemSomos() {
  return (
    <>
      <div className='cards'>
        <h1>Quem Somos</h1>
        <div className='cards__container'>
          <div className='babling-container'>
            <img src="images/logotipo.png" alt="Arthia Logo" />
            <p>A Arthia surgiu em 2020, da união de projetistas com 20 anos de experiência em Modelação e Ferramentaria.</p>
            <p> Nosso objetivo é alcançar a plena satisfação de nossos clientes e parceiros, oferecendo uma prestação de serviço de qualidade, segurança e excelência, buscando sempre melhores resultados.</p>
            <p> Atendemos todo o Brasil pelo: </p>
            <div class="quem-contato">
              <p>WhatsApp — (11) 99155-7277</p>
              <p>Email — arthia@arthiaprojetos.com.br</p>
            </div>

          </div>

          <div className="tec-logos">
            <img src="images/siemens.png" alt="Siemens NX" />
            <img src="images/polyworks.png" alt="Polyworks" />
          </div>
        </div>
      </div>

      <Carousel />
      <Footer />
    </>
  );
}
