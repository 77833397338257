import React, { useState, useEffect } from 'react';
import { Button } from './Button';
import { Link } from 'react-router-dom';
import './styles/Navbar.css';

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener('resize', showButton);

  return (
    <>
      <nav className='navbar'>
        <div className='navbar-container'>
          <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
            Arthia
			<img src="logo.jpg" width="45px" height="45px" alt="Ícone" />
			
          </Link>
          <div className='menu-icon' onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className='nav-item'>
              <a href="/#" className='nav-links' onClick={closeMobileMenu}>Home</a>

              {/* <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                Home
              </Link> */}
            </li>
            <li className='nav-item'>
              <a href="/#servicos" className='nav-links' onClick={closeMobileMenu}>Serviços</a>

              {/* <Link
                to='/#servicos'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                Serviços
              </Link> */}
            </li>
            <li className='nav-item'>
              <a href="/#contato" className='nav-links' onClick={closeMobileMenu}>Contato</a>

              {/* <Link
                to='/#servicos'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                Serviços
              </Link> */}
            </li>
            <li className='nav-item'>
              <a href="/quem-somos#" className='nav-links' onClick={closeMobileMenu}>Quem Somos</a>

              {/* <Link
                to='/products'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                Quem Somos
              </Link> */}
            </li>

            {/*
            <li>
              <Link
                to='/sign-up'
                className='nav-links-mobile'
                onClick={closeMobileMenu}
              >
                (11) 96334-1874 <i class="fas fa-phone"></i>
              </Link>
            </li> */}

          </ul>
          {button && <Button buttonStyle='btn--outline'><a href="tel:+5511963341874">(11) 99155-7277 <i class="fas fa-phone"></i></a></Button>}
        </div>
      </nav>
    </>
  );
}

export default Navbar;
