import React from 'react';
import './styles/Footer.css';
import { Link } from 'react-router-dom';
import ContactForm from "./ContactForm";

function Footer() {
  return (
    <>
    <span id="contato"></span>
    <div className='footer-container' >
      <div class='footer-links'>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <h2>Endereço</h2>
            <p><i class="fas fa-map-marker-alt icon"></i>Rua Dr. Sílvio Noronha, 206</p>
            <p>Cidade Continental</p>
            <p>São Paulo - São Paulo</p>
          </div>
          <div class='footer-link-items'>
            <h2>Contate-nos</h2>
            <p><i class="far fa-envelope icon"></i>arthia@arthiaprojetos.com.br</p>
            <p><i class="fab fa-whatsapp icon"></i>(11) 99155-7277 </p>
          </div>
        </div>
      </div>
      
      <ContactForm></ContactForm>
      <section class='social-media'>
        <div class='social-media-wrap'>
          <div class='footer-logo'>
            <Link to='/' className='social-logo'>
              Arthia
              <img src="logo.jpg" width="45px" height="45px" alt="Ícone"/>
            </Link>
          </div>
          <p class='website-rights'>Arthia Projetos © 2022</p>
          <p className="website-rights">Desenvolvido por <a href="https://github.com/RexynyN" className="git-breno"><i class="fab fa-github"></i></a> e <a href="https://github.com/LucasPreviato" className="git-lucas"><i class="fab fa-github"></i></a></p>
          <div class='social-icons'>
            <Link
              class='social-icon-link facebook'
              to='/'
              target='_blank'
              aria-label='Facebook'
            >
              <i class='fab fa-facebook-f' />
            </Link>
            <Link
              class='social-icon-link instagram'
              to='/'
              target='_blank'
              aria-label='Instagram'
            >
              <i class='fab fa-instagram' />
            </Link>
            <Link
              class='social-icon-link youtube'
              to='/'
              target='_blank'
              aria-label='Youtube'
            >
              <i class='fab fa-youtube' />
            </Link>
            <Link
              class='social-icon-link twitter'
              to='/'
              target='_blank'
              aria-label='Twitter'
            >
              <i class='fab fa-twitter' />
            </Link>
            <Link
              class='social-icon-link twitter'
              to='/'
              target='_blank'
              aria-label='LinkedIn'
            >
              <i class='fab fa-linkedin' />
            </Link>
          </div>
        </div>
      </section>
    </div>
    </>
  );
}

export default Footer;