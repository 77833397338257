import React from 'react';
import './styles/ImgModal.css';


export default function ImgModal(props) {
    return (
        <div class="modal" style={props.hidden ? { display: "none" } : { display: "block" }}>
            <span class="close" onClick={() => props.onChange(true)}>&times;</span>
            <img src={props.source} alt="modalImage" className="modal-content"/>
            <div className="modal-caption">{props.text}</div>
        </div>
    );
}